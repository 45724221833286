import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./BookerSchedulePage.styles";
import BookerScheduleTablePreview from "../BookerScheduleTable/BookerScheduleTablePreview";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { renderChildren } from "utils/router";
import BookingRoute from "routes/Bookings/routes/Booking";

function GridSchedulePage() {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        {/* Child routes */}
        {renderChildren([BookingRoute])}
        {/* Main Route */}
        <Route
          exact
          path={match.path}
          render={() => <BookerScheduleTablePreview />}
        />
      </Switch>
    </>
  );
}

export default GridSchedulePage;

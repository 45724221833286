import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { sizing } from "@material-ui/system";

import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TodayIcon from "@material-ui/icons/Today";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useNotifications } from "modules/notification";
import LoadingSpinner from "components/LoadingSpinner";
import { BOOKINGS_COLLECTION, USERS_COLLECTION } from "constants/firebasePaths";
// import BookingTile from "../BookingTile";
// import NewBookingDialog from "../NewBookingDialog";
import styles from "./AvailabilityNieuwsuurTable.styles";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import PopoverBookMenu from "../BookerSchedulePopover/BookerSchedulePopoverBookMenu";
// import PopoverRequestMenu from "../BookerSchedulePopover/BookerSchedulePopoverRequestMenu";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import UserTile from "../Home/components/UserTile";

const useStyles = makeStyles(styles);
require("dayjs/locale/nl");
dayjs.locale("nl");

function useBookingsList() {
  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  // Get auth from redux state
  const auth = useSelector(({ firebase: { auth } }) => auth);

  useFirestoreConnect([
    {
      collection: BOOKINGS_COLLECTION,
      // where: ["bookedForDate", ">=", dateLimit],
      where: ["planner", "==", "ns71zc6nAW9FJVx8zZY1"],

      // where: ["createdBy", "==", auth.uid],
    },
  ]);

  useFirestoreConnect([
    {
      collection: USERS_COLLECTION,

      // where: ["createdBy", "==", auth.uid],
    },
  ]);

  // Get bookings from redux state
  const bookings = useSelector(
    ({ firestore: { ordered } }) => ordered.bookings
  );

  // Get users from redux state

  const users = useSelector(({ firestore: { ordered } }) => ordered.users);

  // New dialog
  const [newDialogOpen, changeDialogState] = useState(false);
  const toggleDialog = () => changeDialogState(!newDialogOpen);

  function addBooking(newInstance) {
    if (!auth.uid) {
      return showError("You must be logged in to create an booking");
    }
    return firestore
      .add(BOOKINGS_COLLECTION, {
        ...newInstance,
        createdBy: auth.uid,
        createdAt: firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        toggleDialog();
        showSuccess("Booking added successfully");
      })
      .catch((err) => {
        console.error("Error:", err); // eslint-disable-line no-console
        showError(err.message || "Could not add booking");
        return Promise.reject(err);
      });
  }

  return { bookings, users, addBooking, newDialogOpen, toggleDialog };
}

//////////////// DATA GRIDS ///////////////////

const columnsBookings = [
  { field: "id", headerName: "ID", width: 270 },
  { field: "name", headerName: "name", width: 230 },
  { field: "bookedForDate", headerName: "bookedForDate", width: 200 },
  {
    field: "bookedForOrg",
    headerName: "bookedForOrg",

    width: 120,
  },
  {
    field: "combined",
    headerName: "combined fields",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 260,
    valueGetter: (params) =>
      `${params.getValue(params.id, "name") || ""} ${
        params.getValue(params.id, "bookedForDate") || ""
      }`,
  },
];

const columnsUsers = [
  { field: "id", headerName: "ID", width: 270 },
  { field: "firstname", headerName: "firstname", width: 230 },
  { field: "lastname", headerName: "lastname", width: 200 },
  {
    field: "FCMToken",
    headerName: "FCMToken",

    width: 120,
  },
  {
    field: "fullName",
    headerName: "FullName",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 260,
    valueGetter: (params) =>
      `${params.getValue(params.id, "firstname") || ""} ${
        params.getValue(params.id, "lastname") || ""
      }`,
  },
];

function createData(id, name, day1, day2, day3, day4) {
  return { id, name, day1, day2, day3, day4 };
}

////////////////DAYJS/////////////////

require("dayjs/locale/nl");
dayjs.locale("nl");
// const dateOne = dayjs().toISOString();
// const dateTwo = dayjs().add(1, "day").toISOString();
// const dateThree = dayjs().add(2, "day").toISOString();
// const dateFour = dayjs().add(3, "day").toISOString();

function BookerScheduleTablePreview() {
  const [dateOne, setDateOne] = useState(dayjs().toISOString());
  const [dateTwo, setDateTwo] = useState(dayjs().add(1, "day").toISOString());
  const [dateThree, setDateThree] = useState(
    dayjs().add(2, "day").toISOString()
  );
  const [dateFour, setDateFour] = useState(dayjs().add(3, "day").toISOString());

  const addDateOne = () => {
    setDateOne(dayjs(dateOne).add(1, "day").toISOString());
    setDateTwo(dayjs(dateTwo).add(1, "day").toISOString());
    setDateThree(dayjs(dateThree).add(1, "day").toISOString());
    setDateFour(dayjs(dateFour).add(1, "day").toISOString());
  };

  const subtractDateOne = () => {
    setDateOne(dayjs(dateOne).subtract(1, "day").toISOString());
    setDateTwo(dayjs(dateTwo).subtract(1, "day").toISOString());
    setDateThree(dayjs(dateThree).subtract(1, "day").toISOString());
    setDateFour(dayjs(dateFour).subtract(1, "day").toISOString());
  };

  const setToToday = () => {
    setDateOne(dayjs().toISOString());
    setDateTwo(dayjs().add(1, "day").toISOString());
    setDateThree(dayjs().add(2, "day").toISOString());
    setDateFour(dayjs().add(3, "day").toISOString());
  };

  const setToTomorrow = () => {
    setDateOne(dayjs().add(1, "day").toISOString());
    // setDateTwo(dayjs().add(1, "day").toISOString());
    // setDateThree(dayjs().add(2, "day").toISOString());
    // setDateFour(dayjs().add(3, "day").toISOString());
  };

  const dateOneFormatted =
    dayjs(dateOne).format("dddd") + " " + dayjs(dateOne).format("DD/MM YYYY");

  const dateTwoFormatted =
    dayjs(dateTwo).format("dddd") + " " + dayjs(dateTwo).format("DD/MM YYYY");
  const dateThreeFormatted =
    dayjs(dateThree).format("dddd") +
    " " +
    dayjs(dateThree).format("DD/MM YYYY");
  const dateFourFormatted =
    dayjs(dateFour).format("dddd") + " " + dayjs(dateFour).format("DD/MM YYYY");

  const classes = useStyles();
  const { users, bookings, addBooking, newDialogOpen, toggleDialog } =
    useBookingsList();
  const rowsBookings = bookings;
  const rowsUsers = users;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRequest, setAnchorElRequest] = React.useState(null);

  const [selectedBooking, setSelectedBooking] = useState(
    "geen boeking geselecteerd"
  );

  const [selectedUser, setSelectedUser] = useState(
    "geen gebruiker geselecteerd"
  );

  // Show spinner while bookings are loading
  if (!isLoaded(users)) {
    return <LoadingSpinner />;
  }

  if (!isLoaded(bookings)) {
    return <LoadingSpinner />;
  }

  const open = Boolean(anchorEl);
  const openRequest = Boolean(anchorElRequest);
  const id = open ? "simple-popover" : undefined;
  const request = openRequest ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    let currentBooking1 = event.currentTarget.getAttribute("identifier");

    setSelectedBooking(currentBooking1);

    let user = event.currentTarget.getAttribute("useridentifier");

    let selectedUserObject = user;

    setSelectedUser(selectedUserObject);
  };

  const handleClickRequest = (event) => {
    setAnchorElRequest(event.currentTarget);

    let currentBooking1 = event.currentTarget.getAttribute("identifier");

    setSelectedBooking(currentBooking1);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedBooking("geen boeking geselecteerd");
  };

  const handleCloseRequest = () => {
    setAnchorElRequest(null);
    setSelectedBooking("geen boeking geselecteerd");
  };

  const recentBookingsMarkup1Available = (thing, date) =>
    bookings
      .filter((booking) => dayjs(booking.bookedForDate).isSame(date, "day"))
      .filter(
        (booking) => booking.bookedForUserId != "AJqybeX1x4MEV5sDNZad9ma3mu72"
      )
      .filter(
        (booking) =>
          booking.bookingStatus == 10 ||
          booking.bookingStatus == 11 ||
          booking.bookingStatus == 20
      )

      .filter(
        (booking) =>
          booking.bookedForUserId != "DeJqjIEM8gOTzIGYo1GkIDEpDjt2" &&
          booking.bookedForUserId != "74ABjrBWJVZXXWJt8ChC2DFmLqB2"
      )

      .filter(
        (booking) =>
          booking.bookedForOrg == thing || booking.bookedForOrg == "beschikbaar"
      )
      .sort((a, b) => a.bookingStatus - b.bookingStatus)
      .map((booking) => (
        <div>
          <Grid style={{ margin: "20px" }}>
            <Button
              key={booking.id}
              identifier={booking.id}
              useridentifier={booking.bookedForUserId}
              orgidentifier={thing}
              booking={booking}
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              style={
                booking.bookingStatus == 10 ||
                (booking.bookingStatus == 11 && booking.bookedForOrg == thing)
                  ? { width: "170px", color: "white", background: "#006699" }
                  : booking.bookingStatus == 20
                  ? { width: "170px", color: "white", background: "green" }
                  : {
                      width: "170px",
                      color: "white",
                      background: "lightgrey",
                    }
              }
            >
              {booking.bookedForUser}
              {/* {params.row.firstname} */}
            </Button>
          </Grid>
        </div>
      ));

  const recentBookingsMarkup1Request = (thing, date) =>
    bookings
      .filter((booking) => dayjs(booking.bookedForDate).isSame(date, "day"))
      .filter(
        (booking) => booking.bookedForUserId != "AJqybeX1x4MEV5sDNZad9ma3mu72"
      )
      .filter(
        (booking) => booking.bookingStatus == 10 || booking.bookingStatus == 30
      )
      .filter(
        (booking) =>
          booking.bookedForUserId != "DeJqjIEM8gOTzIGYo1GkIDEpDjt2" &&
          booking.bookedForUserId != "74ABjrBWJVZXXWJt8ChC2DFmLqB2"
      )
      .sort((a, b) => a.bookingStatus - b.bookingStatus)
      .map((booking) => (
        <div>
          <Grid style={{ margin: "20px" }}>
            {(booking.bookingStatus == 10 && booking.bookedForOrg != thing) ||
            (booking.bookingStatus == 30 && booking.bookedForOrg == thing) ? (
              <Button
                key={booking.id}
                identifier={booking.id}
                useridentifier={booking.bookedForUserId}
                orgidentifier={thing}
                booking={booking}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                style={{
                  width: "170px",
                  color: "white",
                  background: "#f57f17",
                }}
              >
                {booking.bookedForUser}
              </Button>
            ) : null}
          </Grid>
        </div>
      ));

  const recentBookingsMarkup1Booked = (thing, date) =>
    bookings
      .filter((booking) => dayjs(booking.bookedForDate).isSame(date, "day"))
      .filter(
        (booking) => booking.bookedForUserId != "AJqybeX1x4MEV5sDNZad9ma3mu72"
      )
      .filter(
        (booking) =>
          booking.bookingStatus == 40 && booking.bookedForOrg == thing
      )
      .filter(
        (booking) =>
          booking.bookedForUserId != "DeJqjIEM8gOTzIGYo1GkIDEpDjt2" &&
          booking.bookedForUserId != "74ABjrBWJVZXXWJt8ChC2DFmLqB2"
      )
      // .sort((a, b) => a.firstName - b.firstName)
      .map((booking) => (
        <div>
          <Grid style={{ margin: "20px" }}>
            <Button
              key={booking.id}
              identifier={booking.id}
              useridentifier={booking.bookedForUserId}
              orgidentifier={thing}
              booking={booking}
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              style={{
                width: "170px",
                color: "white",
                background: "#006699",
              }}
              endIcon={<CheckBoxIcon />}
            >
              {booking.bookedForUser}
            </Button>
          </Grid>
        </div>
      ));

  const recentBookingsMarkup1Unavailable = (thing, date) =>
    bookings
      .filter((booking) => dayjs(booking.bookedForDate).isSame(date, "day"))
      .filter(
        (booking) => booking.bookedForUserId != "AJqybeX1x4MEV5sDNZad9ma3mu72"
      )
      .filter(
        (booking) =>
          (booking.bookingStatus >= 30 && booking.bookedForOrg != thing) ||
          (booking.bookingStatus == 60 && booking.bookedForOrg == thing) ||
          (booking.bookingStatus == 11 && booking.bookedForOrg != thing)
      )
      .filter(
        (booking) =>
          booking.bookedForUserId != "DeJqjIEM8gOTzIGYo1GkIDEpDjt2" &&
          booking.bookedForUserId != "74ABjrBWJVZXXWJt8ChC2DFmLqB2"
      )
      // .sort((a, b) => a.bookingStatus - b.bookingStatus)
      .map((booking) => (
        <div>
          <Grid style={{ margin: "20px" }}>
            <Button
              key={booking.id}
              identifier={booking.id}
              useridentifier={booking.bookedForUserId}
              orgidentifier={thing}
              booking={booking}
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              style={{ width: "170px", color: "white", background: "#b9000b" }}
            >
              {booking.bookedForUser}
              {/* {params.row.firstname} */}
            </Button>
          </Grid>
        </div>
      ));

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid style={{ marginRight: "30px" }}>
          <Typography
            style={{
              textAlign: "left",
              // marginLeft: "20px",
              width: "490px",
              padding: "2px",
            }}
            variant="h5"
            // className={classes.pageTitle}
          >
            NIEUWSUUR BESCHIKBAARHEID
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid>
        <IconButton
          aria-label="back"
          onClick={setToToday}
          style={{ transform: "scaleX(-1)", marginLeft: "-00px" }}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="today"
          onClick={setToToday}
          style={{ marginLeft: "0px" }}
        >
          <TodayIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="forward"
          onClick={setToTomorrow}
          style={{ marginLeft: "0px" }}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </Grid> */}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={1}
        // wrap="nowrap"
        // style={{ wrap: "nowrap" }}
      >
        <Grid style={{ marginRight: "30px" }}>
          <Paper md={3} sm={6} xs={12}>
            <Typography
              style={{
                paddingTop: "20px",

                marginTop: "20px",
              }}
              variant="h5"
              className={classes.pageTitle}
              align="center"
            >
              {dayjs(dateOne).format("dddd")}
              <br></br>
              {dayjs(dateOne).format(" DD/MM YYYY")}
            </Typography>

            <Grid
              item
              sm={4}
              xs={12}
              style={{ margin: "20px", height: "400px", width: "200px" }}
            >
              {recentBookingsMarkup1Available("Nieuwsuur", dateOne)}
              {recentBookingsMarkup1Request("Nieuwsuur", dateOne)}
              {recentBookingsMarkup1Booked("Nieuwsuur", dateOne)}
              {recentBookingsMarkup1Unavailable("Nieuwsuur", dateOne)}
            </Grid>
          </Paper>
        </Grid>
        <Grid style={{ marginRight: "30px" }}>
          <Paper md={3} sm={6} xs={12}>
            <Typography
              style={{
                paddingTop: "20px",

                marginTop: "20px",
              }}
              variant="h5"
              className={classes.pageTitle}
              align="center"
            >
              {dayjs(dateTwo).format("dddd")}
              <br></br>
              {dayjs(dateTwo).format(" DD/MM YYYY")}
            </Typography>

            <Grid
              item
              sm={4}
              xs={12}
              style={{ margin: "20px", height: "400px", width: "200px" }}
            >
              {recentBookingsMarkup1Available("Nieuwsuur", dateTwo)}
              {recentBookingsMarkup1Request("Nieuwsuur", dateTwo)}
              {recentBookingsMarkup1Booked("Nieuwsuur", dateTwo)}
              {recentBookingsMarkup1Unavailable("Nieuwsuur", dateTwo)}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {users
          .filter((user) => user.id == selectedUser)
          .map((user) => {
            return (
              <UserTile
                key={user.id}
                name={user.displayName}
                userId={user.id}
                avatarUrl={user.avatarUrl}
                jobtitle={user.jobtitle}
                bio={user.bio}
                showreel={user.showreel}
                tagline={user.tagline}
                show={user.show}
              />
            );
          })}
      </Popover>

      <br></br>
      <Paper
        style={{
          marginLeft: "-5px",
          padding: "15px",
          width: "510px",
          color: "White",
          background: "#EEEEEE",
        }}
      >
        <Button
          style={{ margin: "10px", color: "White", background: "#006699" }}
          variant="contained"
          // onClick={toggleDialog}
        >
          Blauw is voor jou
        </Button>
        <Button
          style={{ margin: "10px", color: "White", background: "green" }}
          variant="contained"
          // onClick={toggleDialog}
        >
          Beschikbaar
        </Button>
        <Button
          style={{ margin: "10px", color: "White", background: "#006699" }}
          variant="contained"
          // onClick={toggleDialog}
          endIcon={<CheckBoxIcon />}
        >
          Ingezet
        </Button>

        <Button
          style={{ margin: "10px", color: "White", background: "#f57f17" }}
          variant="contained"
          // onClick={toggleDialog}
        >
          Beschikbaar in Overleg
        </Button>
        <Button
          style={{ margin: "10px", color: "White", background: "#b9000b" }}
          variant="contained"
          // onClick={toggleDialog}
        >
          Niet Beschikbaar
        </Button>
      </Paper>

      <br></br>
    </div>
  );
}

export default BookerScheduleTablePreview;

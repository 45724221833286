export default (theme) => ({
  flex: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.background.default
        : theme.palette.background.default,
  },
  accountButton: {
    color: "lightgrey",
  },
  themeModeButton: {
    color: "lightgrey",
  },
  signIn: {
    color: "lightgrey",
    textDecoration: "none",
    alignSelf: "center",
  },
});

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import journaal from "../../../../static/journaal.png";
import jeugd from "../../../../static/jeugd.png";
import nieuwsuur from "../../../../static/nieuwsuur.png";
import op3 from "../../../../static/op3.png";
import stories from "../../../../static/stories.png";
import eenvandaag from "../../../../static/1vandaag.png";
import avrotros from "../../../../static/avrotros.png";
import npo from "../../../../static/npo.png";
import strandSmall from "../../../../static/strand-s.jpg";
import strand from "../../../../static/strand.jpg";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";
import HPM from "../../../../static/HPM.svg";

import playicon from "../../../../static/playicon.png";

import Container from "@material-ui/core/Container";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import { Parallax, Background } from "react-parallax";

import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useNotifications } from "modules/notification";
import LoadingSpinner from "components/LoadingSpinner";
import { USERS_COLLECTION } from "constants/firebasePaths";
import UserTile from "../UserTile";

import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
} from "constants/paths";
import styles from "./HomePage.styles";
import UC from "../../../../static/UC.png";
// import HPM from "../../../../static/VIDEONED_2022.svg";

import { createTheme } from "@material-ui/core/styles";
import BookerSchedulePagePreview from "../../../BookerSchedule/components/BookerSchedulePage/BookerSchedulePagePreview";
import { CardMedia } from "@material-ui/core";

const useStyles = makeStyles(styles);

function useUsersList() {
  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  // Get auth from redux state
  const auth = useSelector(({ firebase: { auth } }) => auth);

  // useFirestoreConnect([
  //   {
  //     collection: USERS_COLLECTION,
  //     // where: ["createdBy", "==", auth.uid],
  //   },
  // ]);

  useFirestoreConnect([
    {
      collection: USERS_COLLECTION,
      // where: ["createdBy", "==", auth.uid],
    },
  ]);

  // // Get bookings from redux state
  // const bookings = useSelector(
  //   ({ firestore: { ordered } }) => ordered.bookings
  // );

  // Get users from redux state

  const users = useSelector(({ firestore: { ordered } }) => ordered.users);

  // New dialog
  const [newDialogOpen, changeDialogState] = useState(false);
  const toggleDialog = () => changeDialogState(!newDialogOpen);

  // function addUser(newInstance) {
  //   if (!auth.uid) {
  //     return showError("You must be logged in to create a user");
  //   }
  //   return firestore
  //     .add(USERS_COLLECTION, {
  //       ...newInstance,
  //       createdBy: auth.uid,
  //       createdAt: firestore.FieldValue.serverTimestamp(),
  //     })
  //     .then(() => {
  //       toggleDialog();
  //       showSuccess("User added successfully");
  //     })
  //     .catch((err) => {
  //       console.error("Error:", err); // eslint-disable-line no-console
  //       showError(err.message || "Could not add user");
  //       return Promise.reject(err);
  //     });
  // }

  return { users, newDialogOpen, toggleDialog };
}

function Home() {
  const classes = useStyles();
  const { users, addUser, newDialogOpen, toggleDialog } = useUsersList();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const preventDefault = (event) => event.preventDefault();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const insideStyles = {
    background: "none",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  };

  return (

<div style = {{ height:"100vh", background:"#006697"}}>
  <div style = {{  margin: "auto",
 margin: "0",
 position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
  // border: "3px solid green",
  padding: "10px"}}>

<img style={{ width: "40vw", margin: "20px 20px" }} src={HPM} alt="HPM logo"></img>

  </div>
</div>

//     <div
//       style={{
//         margin: "0",
//       }}
//     >
//       <div
//         // className="player-wrapper"
//         style={{
//           position: "relative",
//           // aspectRatio: 16 / 9,
//           // width: "100%",

//           paddingTop: "42.65%",
//           // paddingTop: "calc( 100vw * 0.4265 )",
//           // paddingBottom: "-100",
//         }}
//       >
//         <img
//           style={{
//             position: "absolute",
//             top: 1,
//             // left: 0,

//             objectFit: "fill",
//           }}
//           width="100%"
//           height="99.5%"
//           src="https://firebasestorage.googleapis.com/v0/b/hpm-video.appspot.com/o/videostill.png?alt=media&token=0740224d-8b45-429a-9d64-1b6290d06677"
//           frameBorder="0"
//           // allow="autoplay; fullscreen"
//           // allowFullScreen
//         ></img>
//         <iframe
//           style={{
//             position: "absolute",
//             top: 0,
//             // left: 0,

//             objectFit: "fill",
//           }}
//           width="100%"
//           height="100%"
//           src="https://player.vimeo.com/video/306358208?autoplay=1&loop=1&autopause=0&muted=1&background=1"
//           frameBorder="0"
//           allow="autoplay; fullscreen"
//           allowFullScreen
//         ></iframe>
//         {/* <iframe
//           style={{
//             position: "absolute",
//             top: 0,
//             // left: 0,

//             objectFit: "fill",
//           }}
//           width="100%"
//           height="100%"
//           src="https://player.vimeo.com/video/636725488?autoplay=1&loop=1&autopause=0&muted=1&background=1"
//           frameBorder="0"
//           allow="autoplay; fullscreen"
//           allowFullScreen
//         ></iframe> */}
//       </div>
//       {/* <div
//         style={{
//           marginTop: "20px",
//           width: "100%",
//           height: "150px",
//           background: "#de1d25",
//         }} */}
//       {/* /> */}
//       <div style={{ marginTop: "20px", padding: "10%", background: "#de1d25" }}>
//         <Typography className={classes.h3} variant="h3" align="center">
//           Wij zijn hpm videocontent
//         </Typography>

//         <Container
//           // className={classes.h3}
//           variant="body"
//           align="center"
//           style={{ color: "white" }}
//         >
//           <Typography
//             style={{
//               padding: "50px 20px 10px 20px",
//               marginBottom: "20px",
//               maxWidth: "950px",

//               background: "none",
//               whiteSpace: "normal",
//             }}
//           >
// Hpm videocontent richt zich vooral op het ontwikkelen en produceren van creatieve videoproducties in de breedste zin van het woord. Of het nu gaat om corporate films, klantcases in beeld & geluid, instructie- of vacaturevideo’s, ons gekwalificeerde videoteam creëert de concepten, scripts en regelt de complete productie van A tot Z. Door onze pragmatische aanpak en werkwijze zijn wij in staat om met een compact team verrassend creatieve producties te creëren, tegen zeer betaalbare tarieven. Onze no nonsense mentaliteit wordt door onze klanten enorm gewaardeerd en zorgt bovendien voor vertrouwen.
//  <br/>  <br/> 

//  Onze mensen hebben tientallen jaren ervaring in audiovisuele producties en weten wat er komt kijken bij het realiseren van film en video voor televisie, narrowcasting systemen en online platforms, waaronder social media. Ons kantoor is gelegen in de natuurrijke plek op Arnhems Buiten. Een ideale uitvalsbasis in het groene hart van Gelderland.  
//  <br/>  <br/> 
//  Wij zijn heel benieuwd wat wij voor uw organisatie kunnen betekenen en gaan hierover graag in gesprek. Bel ons gerust voor een vrijblijvende afspraak en een heerlijke bak koffie.
 
//    </Typography>
//         </Container>
        
//       </div>
//       <div style={{ marginTop: "20px", paddingTop: "5%", background: "none" }}>
//         <Typography
//           className={classes.h3}
//           variant="h3"
//           align="center"
//           style={{ color: "black" }}
//         >
//           Ervaren cameramensen
//         </Typography>

//         <Container
//           // className={classes.h3}
//           variant="body"
//           align="center"
//           style={{ color: "grey" }}
//         >
//           <Typography
//             style={{
//               padding: "50px 20px 10px 20px",
//               marginBottom: "20px",
//               maxWidth: "950px",

//               background: "none",
//               whiteSpace: "normal",
//             }}
//           >
//           Voor de opnames kunnen wij putten uit een pool van tien vaste cameramensen die binnen HPM Video werken voor o.a. de NOS, Nieuwsuur, Een Vandaag en Zembla. Hierdoor zijn wij zeer flexibel en vinden voor de meest uiteenlopende producties snel de juiste cameraman/-vrouw voor de klus.
//           </Typography>
//         </Container>
//         <Container
//           style={{
//             padding: "50px 80px 70px 0px",
//             align: "center",
//             height: "100px",
//             display: "flex",
//             justifyContent: "center",
//             marginTop: "20px",
//             marginBottom: "40px",
//             overflow: "hidden",
//           }}
//         >
//           <Slider
//             style={{ marginLeft: "160px", maxWidth: "900px" }}
//             {...settings}
//           >
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={npo} />
//             </div>
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={jeugd} />
//             </div>
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={nieuwsuur} />
//             </div>
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={op3} />
//             </div>
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={eenvandaag} />
//             </div>
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={journaal} />
//             </div>
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={avrotros} />
//             </div>
//             <div>
//               <img style={{ width: "70px", height: "70px" }} src={stories} />
//             </div>
//           </Slider>
//         </Container>
//       </div>
//       <div id={"reel"}></div>
//       {/* // /////////////////////////// static reel ///////////////////////// */}
//       <div
//         style={{
//           margin: "10% 0 20px 0",
//           background: "none",
//           position: "relative",
//         }}
//         className={classes.reelStatic}
//       >
//         <img
//           src={strandSmall}
//           style={{
//             // position: "absolute",
//             // top: 0,
//             width: "100%",
//           }}
//         />
//         <img
//           onClick={handleOpen}
//           style={{
//             position: "absolute",
//             top: "30%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             height: "30%",
//             minHeight: "60px",
//             maxHeight: "100px",
//           }}
//           src={playicon}
//         />

//         <Typography
//           className={classes.h3}
//           variant="h3"
//           align="center"
//           // style={{ color: "white", marginTop: "40%" }}
//           style={{
//             position: "absolute",
//             top: "75%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             height: "80px",
//           }}
//         >
//           Showreel
//         </Typography>
//       </div>
//       {/* //////////////////// mobile reel with parallax ///////////////////////// */}
//       <div className={classes.reelMobile}>
//         {" "}
//         <Parallax bgImage={strand} strength={-120}>
//           <div style={{ height: 200 }}>
//             <div style={insideStyles}>
//               <Typography
//                 // className={classes.h3}
//                 variant="h3"
//                 align="center"
//                 style={{
//                   fontWeight: "bold",
//                   fontSize: "1.6rem",
//                   color: "white",
//                   marginTop: "-70px",
//                 }}
//               >
//                 Showreel
//               </Typography>
//               <img
//                 onClick={handleOpen}
//                 style={{
//                   position: "absolute",
//                   top: "50%",
//                   left: "50%",
//                   transform: "translate(-50%,-50%)",
//                   height: "80px",
//                 }}
//                 src={playicon}
//               />
//             </div>
//           </div>
//         </Parallax>
//       </div>
//       {/* ////////////////////// reel with parallax //////////////////// s{" "} */}
//       <div className={classes.reelParallax}>
//         {" "}
//         <Parallax bgImage={strand} strength={-200}>
//           <div style={{ height: 650 }}>
//             <div style={insideStyles}>
//               <Typography
//                 className={classes.h3}
//                 variant="h3"
//                 align="center"
//                 style={{ color: "white", marginTop: "-180px" }}
//               >
//                 Showreel
//               </Typography>
//               <img
//                 onClick={handleOpen}
//                 style={{
//                   position: "absolute",
//                   top: "50%",
//                   left: "50%",
//                   transform: "translate(-50%,-50%)",
//                   height: "80px",
//                 }}
//                 src={playicon}
//               />
//             </div>
//           </div>
//         </Parallax>
//       </div>
//       {/* ////////////////////////////////////////////////////////////// */}
//       <div
//         id={"team"}
//         className={classes.root}
//         style={{
//           marginTop: "20px",
//           paddingTop: "5%",
//           background: "none",
//         }}
//       />
//       <Typography
//         className={classes.h3}
//         variant="h3"
//         align="center"
//         style={{ color: "grey" }}
//       >
//         Team
//       </Typography>

//       <div className={classes.rootTiles}>
//         <div
//           className={classes.tiles}
//           style={{ paddingTop: "3%", background: "none" }}
//           role="list"
//         >
//           {!isEmpty(users) ? (
//             users
//               .filter((user) => user.show == "2")
//               .sort((a, b) => a.sortfield - b.sortfield)
//               .map((user, ind) => {
//                 return (
//                   <UserTile
//                     key={user.id}
//                     name={user.displayName}
//                     userId={user.id}
//                     avatarUrl={user.avatarUrl}
//                     jobtitle={user.jobtitle}
//                     bio={user.bio}
//                     showreel={user.showreel}
//                     tagline={user.tagline}
//                     show={user.show}
//                   />
//                 );
//               })
//           ) : (
//             <Paper className={classes.empty}>
//               <Typography>Laden...</Typography>
//             </Paper>
//           )}
//         </div>
//       </div>

//       <div className={classes.rootTiles}>
//         <div
//           className={classes.tiles}
//           style={{ paddingTop: "3%", background: "none" }}
//           role="list"
//         >
//           {!isEmpty(users) ? (
//             users
//               .filter((user) => user.show != "2")
//               .map((user, ind) => {
//                 return (
//                   <UserTile
//                     key={user.id}
//                     name={user.displayName}
//                     userId={user.id}
//                     avatarUrl={user.avatarUrl}
//                     jobtitle={user.jobtitle}
//                     bio={user.bio}
//                     showreel={user.showreel}
//                     tagline={user.tagline}
//                     show={user.show}
//                   />
//                 );
//               })
//           ) : (
//             <Paper className={classes.empty}>
//               <Typography>Laden...</Typography>
//             </Paper>
//           )}
//         </div>
//       </div>
//       <div
//         style={{
//           position: "relative",
//           bottom: 0,

//           width: "100%",
//           height: "280px",
//           background: "gray",
//           paddingTop: "20px",
//         }}
//       >
//         {/* <p style={{ textAlign: "center" }}>
//           <strong>
//             <a href="mailto:info@hpm.nl">info@hpm.nl</a>
//           </strong>
//           <strong>0650015000</strong>
//         </p> */}
//         <p style={{ textAlign: "center" }}>
//           hpm videocontent bv  | Bezoekadres Utrechtseweg 310 gebouw B04 Arnhem  |  Postadres Amsterdamseweg 216 6813 GK Arnhem  | 
//           088 022 022 22
//         </p>
//         <div style={{ textAlign: "center" }}>
//           {/* <Typography variant="h5">
//             <Typography
//               key="Email"
//               component="a"
//               href="mailto:info@hpm.nl"
//               // onClick={preventDefault}
//               variant="inherit"
//               color="inherit"
//             >
//               info@hpm.nl |
//             </Typography>
//             {"  "}
//             <a
//               style={{ textDecorations: "none", color: "inherit" }}
//               href="tel:0650015000"
//             >
//               {" "}
//               0650015000
//             </a>
//           </Typography> */}
//         </div>
//         <p style={{ textAlign: "center" }}>
//           <IconButton
//             target="_blank"
//             href="https://wa.me/message/7HM7HPYOQ7WRB1"
//             style={{
//               color: "black",
//             }}
//             // color="primary"
//             aria-label="WhatsApp"
//           >
//             <WhatsAppIcon />
//           </IconButton>
//           <IconButton
//             target="_blank"
//             href="https://www.facebook.com/hpmvideo/"
//             style={{
//               color: "black",
//             }}
//             // color="primary"
//             aria-label="Facebook"
//           >
//             <FacebookIcon />
//           </IconButton>
//           <IconButton
//             target="_blank"
//             href="https://www.instagram.com/hpmvideo/"
//             style={{
//               color: "black",
//             }}
//             // color="primary"
//             aria-label="Instagram"
//           >
//             <InstagramIcon />
//           </IconButton>
//           <IconButton
//             target="_blank"
//             href="https://nl.linkedin.com/company/videocontent-2022productie-bv"
//             style={{
//               color: "black",
//             }}
//             // color="primary"
//             aria-label="LinkedIn"
//           >
//             <LinkedInIcon />
//           </IconButton>
//         </p>
//       </div>
//       <Dialog
//         onClose={handleClose}
//         aria-labelledby="simple-dialog-title"
//         open={open}
//         // style={{ width: "1200px" }}
//       >
//         <DialogTitle id="simple-dialog-title">Showreel</DialogTitle>
//         <iframe
//           src="https://player.vimeo.com/video/440927222?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=836dee7507"
//           width="600px"
//           height="400px"
//           frameBorder="0"
//           allow="autoplay; fullscreen; picture-in-picture"
//           allowFullScreen
//           title={`Showreel}`}
//         ></iframe>
//       </Dialog>
//     </div>
  );
}

export default Home;

import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { BOOKINGS_PATH } from "constants/paths";
import { useNotifications } from "modules/notification";
import styles from "./BookingTile.styles";

const useStyles = makeStyles(styles);

function BookingTile({ name, bookingId, showDelete }) {
  const classes = useStyles();
  const history = useHistory();
  const { showError, showSuccess } = useNotifications();
  const firestore = useFirestore();

  function goToBooking() {
    return history.push(`${BOOKINGS_PATH}/${bookingId}`);
  }

  function deleteBooking() {
    return firestore
      .delete(`bookings/${bookingId}`)
      .then(() => showSuccess("Booking deleted successfully"))
      .catch((err) => {
        console.error("Error:", err); // eslint-disable-line no-console
        showError(err.message || "Could not delete booking");
        return Promise.reject(err);
      });
  }

  return (
    <Paper className={classes.root} role="listitem">
      <div className={classes.top}>
        <span className={classes.name} onClick={goToBooking}>
          {name || "No Name"}
        </span>
        {showDelete ? (
          <Tooltip title="Delete">
            <IconButton onClick={deleteBooking}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Paper>
  );
}

BookingTile.propTypes = {
  bookingId: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  name: PropTypes.string,
};

BookingTile.defaultProps = {
  showDelete: true,
};

export default BookingTile;

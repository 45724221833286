export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    paddingTop: theme.spacing(2),
  },

  rootTiles: {
    padding: theme.spacing(4),
    flexGrow: "2",
    boxSizing: "border-box",
    //    // overflowY: "scroll",
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  tiles: {
    display: "flex",
    ...theme.flexColumnCenter,
    justifyContent: "center",
    flexWrap: "wrap",
    "-webkit-flex-flow": "row wrap",
  },
  empty: {
    padding: theme.spacing(4),
  },

  section: {
    ...theme.flexColumnCenter,
    padding: theme.spacing(2),
    textAlign: "center",
  },

  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: "cover",
  },

  reelStatic: {
    "@media (min-width: 600px)": {
      display: "none",
    },
    "@media (max-width: 600px)": {
      display: "none",
    },
  },

  reelMobile: {
    "@media (min-width: 800px)": {
      display: "none",
    },
  },

  reelParallax: {
    "@media (max-width: 800px)": {
      display: "none",
    },
  },

  h3: {
    color: "white",
    fontWeight: "bold",
    fontSize: "1.5rem",
    "@media (min-width:800px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.4rem",
    },
  },
});

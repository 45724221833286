import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { USERS_PATH } from "constants/paths";
import { useNotifications } from "modules/notification";
import styles from "./UserTile.styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const { onClose, open, bio, name, showreel, jobtitle, avatarUrl } = props;
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  // const showreel = "574418645";

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle> {name}</DialogTitle>
      <Typography
        gutterBottom
        style={{ marginLeft: "25px" }}
        color="textSecondary"
      >
        {" "}
        {jobtitle}{" "}
      </Typography>
      {showreel ? (
        <iframe
          src={`https://player.vimeo.com/video/${showreel}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=eecb27398e`}
          width="100%"
          height="400"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title={`Showreel ${name}`}
        ></iframe>
      ) : (
        <CardMedia
          component="img"
          className={classes.media}
          height="350"
          width="100%"
          image={avatarUrl}
          title={name}
          marginBottom={40}
          style={{ margin: "30px 0 30px 0" }}
        />
      )}

      <Typography style={{ margin: "0 25px 25px 25px" }} component="p">
        {bio || "Geen omschrijving"}
      </Typography>
    </Dialog>
  );
}

function UserTile({
  name,
  userId,
  avatarUrl,
  bio,
  jobtitle,
  showreel,
  showDelete,
  tagline,
  show,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const classes = useStyles();
  const history = useHistory();
  const { showError, showSuccess } = useNotifications();
  const firestore = useFirestore();

  const profile = useSelector(({ firebase: { profile } }) => profile);

  function goToUser() {
    return history.push(`${USERS_PATH}/${userId}`);
  }

  function deleteUser() {
    return firestore
      .delete(`users/${userId}`)
      .then(() => showSuccess("User deleted successfully"))
      .catch((err) => {
        console.error("Error:", err); // eslint-disable-line no-console
        showError(err.message || "Could not delete user");
        return Promise.reject(err);
      });
  }

  return show ? (
    <div>
      <Card className={classes.card} role="listitem">
        <CardMedia
          component="img"
          className={classes.media}
          height="160"
          width="100%"
          image={avatarUrl}
          title={name}
        />{" "}
        <br></br>
        {/* <span className={classes.name} onClick={goToUser}>
      {name || "No Name"}
    </span> */}
        <CardContent>
          <Typography
            gutterBottom
            variant="inherit"
            component="h2"
            onClick={profile.role && profile.role === "admin" ? goToUser : null}
          >
            {name || "No Name"}
          </Typography>
          <Typography
            gutterBottom
            style={{ fontSize: "0.89rem" }}
            color="textSecondary"
          >
            {jobtitle || "Geen omschrijving"}
          </Typography>
          <Typography
            style={{ height: "70px", overflow: "hidden" }}
            component="p"
          >
            {tagline || null}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className={classes.button}
            size="small"
            color="inherit"
            onClick={handleClickOpen}
          >
            {showreel ? "Showreel" : bio ? "Lees Meer" : null}
          </Button>
        </CardActions>
        {/* {showDelete ? (
      <Tooltip title="Delete">
        <IconButton onClick={deleteUser}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ) : null} */}
      </Card>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        bio={bio}
        showreel={showreel}
        name={name}
        jobtitle={jobtitle}
        avatarUrl={avatarUrl}
      />
    </div>
  ) : null;
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

UserTile.propTypes = {
  userId: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  name: PropTypes.string,
};

UserTile.defaultProps = {
  showDelete: true,
};

export default UserTile;

import { loadable } from "utils/router";

export default {
  path: ":bookingId",
  component: loadable(() =>
    import(
      /* webpackChunkName: 'Project' */ "./components/BookingPage/BookingPage"
    )
  ),
};

import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";

import MenuItem from "@mui/material/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import {
  ACCOUNT_PATH,
  USERS_PATH,
  ORGANISATIONS_PATH,
  BOOKERORGANISATION_PATH,
  PLANNERORGANISATION_PATH,
  BOOKINGS_PATH,
  PLANNERSCHEDULE_PATH,
  BOOKERSCHEDULE_PATH,
  CREWSCHEDULE_PATH,
  PUSHMESSAGETEST_PATH,
  AVAILABILITY_PATH,
  RELATIONS_PATH,
  MAP_PATH,
} from "constants/paths";
import styles from "./Navbar.styles";

const useStyles = makeStyles(styles);

function AccountMenu() {
  const profile = useSelector(({ firebase: { profile } }) => profile);
  const classes = useStyles();
  const [anchorEl, setMenu] = useState(null);
  const firebase = useFirebase();

  function closeAccountMenu() {
    setMenu(null);
  }
  function handleMenuClick(e) {
    setMenu(e.target);
  }
  function handleLogout() {
    closeAccountMenu(); // redirect to '/' handled by UserIsAuthenticated HOC
    return firebase.logout();
  }

  return (
    <>
      <IconButton
        aria-owns={anchorEl ? "menu-appbar" : null}
        aria-haspopup="true"
        onClick={handleMenuClick}
        classes={{ root: classes.accountButton }}
        size="medium"
      >
        <AccountCircle fontSize="large" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}
      >
        <MenuItem component={Link} to={ACCOUNT_PATH} onClick={closeAccountMenu}>
          Account
        </MenuItem>

        {/* {profile.role && profile.role === "admin" ? (
          <MenuItem component={Link} to={USERS_PATH} onClick={closeAccountMenu}>
            Gebruikers
          </MenuItem>
        ) : null} */}
        {profile.role && profile.role === "admin" ? (
          <MenuItem component={Link} to={USERS_PATH} onClick={closeAccountMenu}>
            Gebruikers
          </MenuItem>
        ) : null}

        {profile.role && profile.role === "admin" ? (
          <MenuItem
            component={Link}
            to={ORGANISATIONS_PATH}
            onClick={closeAccountMenu}
          >
            Organisaties
          </MenuItem>
        ) : null}

        {profile.type && profile.type[0] === "Planner" ? (
          <MenuItem
            component={Link}
            to={PLANNERORGANISATION_PATH}
            onClick={closeAccountMenu}
          >
            Planner Organisatie
          </MenuItem>
        ) : null}

        {profile.type && profile.type[0] === "Booker" ? (
          <MenuItem
            component={Link}
            to={BOOKERORGANISATION_PATH}
            onClick={closeAccountMenu}
          >
            Booker Organisatie
          </MenuItem>
        ) : null}

        {profile.role && profile.role === "admin" ? (
          <MenuItem
            component={Link}
            to={BOOKINGS_PATH}
            onClick={closeAccountMenu}
          >
            Boekingen
          </MenuItem>
        ) : null}

        {profile.type && profile.type[0] === "Planner" ? (
          <MenuItem
            component={Link}
            to={PLANNERSCHEDULE_PATH}
            onClick={closeAccountMenu}
          >
            Planner Schedule
          </MenuItem>
        ) : null}

        {profile.type && profile.type[0] === "Crew" ? (
          <MenuItem
            component={Link}
            to={CREWSCHEDULE_PATH}
            onClick={closeAccountMenu}
          >
            Crew Schedule
          </MenuItem>
        ) : null}

        {profile.type && profile.type[0] === "Booker" ? (
          <MenuItem
            component={Link}
            to={BOOKERSCHEDULE_PATH}
            onClick={closeAccountMenu}
          >
            Booker Schedule
          </MenuItem>
        ) : null}

        {profile.role && profile.role === "admin" ? (
          <MenuItem
            component={Link}
            to={PUSHMESSAGETEST_PATH}
            onClick={closeAccountMenu}
          >
            Push Message Test
          </MenuItem>
        ) : null}

        <MenuItem component={Link} to={MAP_PATH} onClick={closeAccountMenu}>
          Kaart
        </MenuItem>

        {profile.role && profile.role === "admin" ? (
          <MenuItem
            component={Link}
            to={RELATIONS_PATH}
            onClick={closeAccountMenu}
          >
            Relaties
          </MenuItem>
        ) : null}

        {profile.role && profile.role === "admin" ? (
          <MenuItem
            component={Link}
            to={AVAILABILITY_PATH}
            onClick={closeAccountMenu}
          >
            Beschikbaarheid
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleLogout}>Log Uit</MenuItem>
      </Menu>
    </>
  );
}

export default AccountMenu;

export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: "2",
    boxSizing: "border-box",
    // overflowY: "scroll",
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  tiles: {
    display: "flex",
    ...theme.flexColumnCenter,
    justifyContent: "center",
    flexWrap: "wrap",
    "-webkit-flex-flow": "row wrap",
  },
  empty: {
    padding: theme.spacing(4),
  },

  "@keyframes blinker": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  blinkButton: {
    animationName: "$blinker",
    animationDuration: "0.9s",
    animationTimingFunction: "jump-both",
    animationIterationCount: "infinite",

    opacity: "0",
  },
});

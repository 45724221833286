import React from "react";
import { Switch, Route } from "react-router-dom";
import CoreLayout from "../layouts/CoreLayout";
import Home from "./Home";
import LoginRoute from "./Login";
import SignupRoute from "./Signup";
// import ProjectsRoute from "./Projects";
import AccountRoute from "./Account";
import NotFoundRoute from "./NotFound";
import OrganisationsRoute from "./Organisations";
import RelationsRoute from "./Relations";

import BookerOrganisationRoute from "./BookerOrganisation";
import PlannerOrganisationRoute from "./PlannerOrganisation";

import UsersRoute from "./Users";
import BookingssRoute from "./Bookings";
import GridScheduleRoute from "./PlannerSchedule";
import BookerScheduleRoute from "./BookerSchedule";
import CrewScheduleRoute from "./CrewSchedule";

import BookerScheduleAllRoute from "./BookerScheduleAll";

import PushMessageTestRoute from "./PushMessageTest";
import AvailabilityRoute from "./Availability";
import AvailabilityJournaalRoute from "./AvailabilityJournaal";
import AvailabilityNieuwsuurRoute from "./AvailabilityNieuwsuur";
import AvailabilityEenvandaagRoute from "./AvailabilityEenvandaag";
import AvailabilityGeneralRoute from "./AvailabilityGeneral";
import AvailabilityNosRoute from "./AvailabilityJournaal/indexNOS";

import MapRoute from "./Map";

export default function createRoutes() {
  return (
    <CoreLayout>
      <Switch>
        <Route exact path={Home.path} component={() => <Home.component />} />
        {
          /* Build Route components from routeSettings */
          [
            AccountRoute,
            // ProjectsRoute,
            SignupRoute,
            LoginRoute,
            UsersRoute,
            OrganisationsRoute,
            RelationsRoute,
            BookerOrganisationRoute,
            PlannerOrganisationRoute,
            BookingssRoute,
            GridScheduleRoute,
            BookerScheduleRoute,
            CrewScheduleRoute,

            BookerScheduleAllRoute,
            PushMessageTestRoute,
            AvailabilityRoute,
            AvailabilityJournaalRoute,
            AvailabilityNosRoute,
            AvailabilityNieuwsuurRoute,
            AvailabilityEenvandaagRoute,
            AvailabilityGeneralRoute,
            MapRoute,

            /* Add More Routes Here */
          ].map((settings) => (
            <Route key={`Route-${settings.path}`} {...settings} />
          ))
        }
        <Route component={NotFoundRoute.component} />
      </Switch>
    </CoreLayout>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  PopButtonBlue,
  PopButtonRed,
  PopButtonOrange,
  PopButtonGreenOutline,
  PopButtonRedOutline,
} from "./BookerSchedulePopButton";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, blue, red, orange) {
  return { name, blue, red, orange };
}

export default function PopoverMenu(props) {
  const classes = useStyles();

  const [selectedBooking, setSelectedBooking] = useState(props.selectedbooking);

  const selectedbookingid = selectedBooking;

  const rows = [
    createData(
      "NOS",
      <PopButtonBlue clientName={"NOS"} selectedbookingid={selectedBooking} />,
      <PopButtonRed clientName={"NOS"} selectedbookingid={selectedBooking} />,
      <PopButtonOrange clientName={"NOS"} selectedbookingid={selectedBooking} />
    ),
    createData(
      "Nieuwsuur",
      <PopButtonBlue
        clientName={"Nieuwsuur"}
        selectedbookingid={selectedBooking}
      />,
      <PopButtonRed
        clientName={"Nieuwsuur"}
        selectedbookingid={selectedBooking}
      />,
      <PopButtonOrange
        clientName={"Nieuwsuur"}
        selectedbookingid={selectedBooking}
      />
    ),
    createData(
      "Een Vandaag",
      <PopButtonBlue
        clientName={"Een Vandaag"}
        selectedbookingid={selectedBooking}
      />,
      <PopButtonRed
        clientName={"Een Vandaag"}
        selectedbookingid={selectedBooking}
      />,
      <PopButtonOrange
        clientName={"Een Vandaag"}
        selectedbookingid={selectedBooking}
      />
    ),
    createData(
      "HPM",
      <PopButtonBlue clientName={"HPM"} selectedbookingid={selectedBooking} />,
      <PopButtonRed clientName={"HPM"} selectedbookingid={selectedBooking} />,
      <PopButtonOrange clientName={"HPM"} selectedbookingid={selectedBooking} />
    ),
    createData(
      "LBL",
      <PopButtonBlue clientName={"LBL"} selectedbookingid={selectedBooking} />,
      <PopButtonRed clientName={"LBL"} selectedbookingid={selectedBooking} />,
      <PopButtonOrange clientName={"LBL"} selectedbookingid={selectedBooking} />
    ),
  ];

  return (
    <div>REQUEST BOOKING</div>
    // <TableContainer>
    //   <Table className={classes.table} size="small" aria-label="a dense table">
    //     <TableHead>
    //       <TableRow>
    //         <TableCell></TableCell>
    //         <TableCell align="right"></TableCell>
    //         <TableCell align="right">
    //           <PopButtonGreenOutline selectedbookingid={selectedBooking} />
    //         </TableCell>
    //         <TableCell align="right">
    //           <PopButtonRedOutline selectedbookingid={selectedBooking} />
    //         </TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {rows.map((row) => (
    //         <TableRow key={row.name}>
    //           <TableCell component="th" scope="row">
    //             {row.name}
    //           </TableCell>
    //           <TableCell align="right">{row.blue}</TableCell>
    //           <TableCell align="right">{row.red}</TableCell>
    //           <TableCell align="right">{row.orange}</TableCell>
    //         </TableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
  );
}
